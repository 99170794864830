import { Container, FormLogin } from "./styles";

import logo from '../../assets/images/Logo.png';
import person from '../../assets/images/person.jpg';

export const Login = () => {
  return (
    <Container>
      <FormLogin>
        <div className="cabecalho">
          <img src={person} alt="" />
          <h5>Nome da Empresa</h5>
        </div>
        <div className="form">
          <div>
            <i className="fa fa-user icon"></i>
            <input type="text" placeholder="Login"/>
          </div>
          <div>
          <i className="fas fa-lock icon"></i> 
          <input type="password" placeholder="Senha"/>
          </div>
          <a href=".../">Esqueci minha senha</a>
          <button>Acessar</button>
        </div>
        <div className="footer">
          <img src={logo} alt="Logo" />
        </div>
      </FormLogin>
    </Container>
  );
}