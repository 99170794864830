import React, { useState,  useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
// import CardActions from "@material-ui/core/CardActions";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
// import { theme } from "../../../styles/theme";
// import BrandCardHeader from '@mui-treasury/components/cardHeader/brand';
import moment from "moment";
// import { sizing } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "550px",
    boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  displayContent: {
    display: "inline",
  },
  contentWrapper: {
    marginTop: "12px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  smallIcon: {
    padding: "1px",
  }
}));

function CardItens(props) {

  const estiloTableHeader = {
    backgroundColor: "#dfdfdf",
    padding: "0px 5px 0px 5px"
  }

  const estiloTableHeaderLeft = {
    backgroundColor: "#dfdfdf",
    padding: "0px 5px 0px 5px",
    borderTopLeftRadius: "0.3em",
    borderBottomLeftRadius: "0.3em"
  }

  const estiloTableHeaderRight = {
    backgroundColor: "#dfdfdf",
    padding: "0px 5px 0px 5px",
    borderTopRightRadius: "0.3em",
    borderBottomRightRadius: "0.3em"
  }

  const estiloTableData = {
    borderRadius: "0.3em",
    fontSize: "14px"
    //backgroundColor: "#efefef"
  }

  const formataEndereco = (pedido) => {
    const {cliente} = pedido;
    // const {formas_pgto} = pedido;
    // const {pedidos_itens} = pedido;

    return "".concat(
      cliente.endereco.endereco,
      ", ",
      cliente.endereco.numero,
      " - ",
      cliente.endereco.bairro
    );
  };


  return (
    <div style={{backgroundColor: "#ffffff", padding: "0.5em 0"}}>
      <div style={{padding: "0 0.1em"}}>
        <Typography style={{textAlign: "center", fontWeight: "bold", backgroundColor: "rgb(223, 223, 223)", borderRadius: "0.3em", paddingBottom: "0.1em"}}>Informações do Cliente</Typography> {/* Header */}

        <Typography>
            <div>
              <span><b>Endereço: </b></span>
              <span>{formataEndereco(props.pedido)}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
              <span><b>Forma de pagamento: </b>{props.pedido.formas_pagto.descricao} </span>
              <span><b>{"Hora: "}</b>{moment(props.pedido.data_hora).format('HH:mm:ss')}</span>
            </div>
        </Typography>

        <Typography style={{marginBottom: "0.5em"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center'}}>
            <span><b>E-mail: </b>{props.pedido.cliente.email} </span>
            <span><b>Contato: </b>{props.pedido.cliente.telefone} </span>
          </div>
        </Typography>
        
        <Typography paragraph> 
            <div>
              <center>
              <table cellPadding="5" cellSpacing="0" width="100%">
                <thead>
                  <th style={estiloTableHeaderLeft}>ID</th>
                  <th style={estiloTableHeader}>Produto</th>
                  <th style={estiloTableHeader}>Quant.</th>
                  <th style={estiloTableHeader}>Valor</th>
                  <th style={estiloTableHeaderRight}>Total</th>
                </thead>
                <tbody>
                  {props.pedido.pedidos_itens.map((itens) => {
                    return (
                      <tr>
                        <td style={estiloTableData}>{itens.produto_id}</td>
                        <td style={estiloTableData}>{itens.nomeproduto}</td>
                        <td style={estiloTableData}>{itens.qtde}</td>
                        <td style={estiloTableData}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itens.preco) }</td>
                        <td style={estiloTableData}>{itens.total}</td>
                      </tr>
                    )}
                  )}
                </tbody>
              </table>
              </center>
            </div> 
        </Typography>
      </div>
    </div>
  );
}

function CardPedido({ pedido }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  //let badge;

  const handleExpandClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {

  }, []);

  // const IntegradoBadge = (
  //   <Chip
  //     style={{ backgroundColor: "#3DF167", color: "white", fontWeight: "bold" }}
  //     label="Integrado"
  //     size="small"
  //   />
  // );
  // const CanceladoBadge = (
  //   <Chip
  //     style={{ backgroundColor: "#EF3E36", color: "white", fontWeight: "bold" }}
  //     label="Cancelado"
  //     size="small"
  //   />
  // );
  const PendenteBadge = (
    <Chip
      style={{ backgroundColor: "#FFEA00", color: "white"}}
      label="Pendente"
      size="small"
    />
  );

  return (
    <div className={classes.contentWrapper}>
      <Card className={classes.root} variant="outlined">
        <CardContent style={{ paddingBottom: "1em" }}>  
          <div style={{ display: "flex", flexDirection: "column"}}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
              <div>
                <b>Nº {pedido.numero_pedido}</b>
              </div>
              <div>{pedido.cliente.nome}</div>
              {PendenteBadge}
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'left' }}>
                <IconButton
                  className={clsx(classes.expand, { [classes.expandOpen]: isOpen, }, classes.smallIcon)} 
                  onClick={handleExpandClick}
                  aria-expanded={isOpen}
                  aria-label="Ver mais">
                  <ExpandMoreIcon/>
                </IconButton>     
              </div> 
            </div>
          </div>
        </CardContent>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <CardContent>
            <CardItens pedido={pedido}/>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}

export default CardPedido;
