import React, { useState, useEffect } from "react";
import {useHistory,Redirect} from "react-router-dom";
import Header from "../../../components/painel/headerPainel";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import api from "../../../services/api";
import Menu from "../../../components/painel/menuPainel";
import validaToken from "../../../helpers/validaToken";

const Configuracoes = (props) => {
  const [dados, setDados] = useState({
    id: 0,
    cnpj: "",
    razao_social: "",
    fantasia: "",
    cep: "",
    endereco_completo: "",
    endereco_nro: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    telefone: "",
    whatsapp: "",
    email: "",
    loja_aberta: false,
    tempo_medio_pedido: 0,
    valor_taxa_entrega: 0,
    flag_entrega: false,
    flag_retirada: false,
    hora_abertura: "",
    hora_fechamento: "",
    flag_apenascardapio: false,
    senha: "",
    guiid: "",
  });

  const history = useHistory();

  const handleSalvar = async (e) => {    
    const response = await api.put("/estabelecimento", dados);

    if (response.status === 200) history.push("/menu");
  };

  useEffect(() =>{
    async function getInicialEstab(){
      const response = await api.get("/estabelecimento");
      setDados(response.data[0]);
    }
    getInicialEstab() 
   }, []);
  

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setDados({
      ...dados,
      [name]: checked,
    });
  };

  const handleChangeText = (event) => {
    const { name, value } = event.target;
    setDados({
      ...dados,
      [name]: value,
    });
  };

  const Space = () => <div style={{ height: "0.75rem" }}></div>;

  return (
    <>
      {!validaToken() ? <Redirect to="/Painel"/> : null}
      <Header goBack titulo="Configurações Gerais"/>
      <Menu />
      <br />
      <Container
        maxWidth="sm"
        style={{
          height: "14em",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          padding: "1rem",
        }}
      >
        <h3
          color="#f05a22"
          style={{ textDecoration: "bold", color: "#f05a22" }}
        >
          Configurações Gerais
        </h3>
        <Space />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "0px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={dados.loja_aberta}
                  name="loja_aberta"
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Loja aberta"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={dados.flag_apenascardapio}
                  name="flag_apenascardapio"
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Apenas visualização"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={dados.flag_entrega}
                  name="flag_entrega"
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Entrega"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={dados.flag_retirada}
                  name="flag_retirada"
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Retirada"
            />
          </div>
        </div>
        <Space />
      </Container>
      <Space />
      <Container
        maxWidth="sm"
        style={{
          height: "13em",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          padding: "1rem",
        }}
      >
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="filled-adornment-amount">
            Valor da taxa de entrega
          </InputLabel>
          <FilledInput
            id="filled-adornment-amount"
            name="valor_taxa_entrega"
            value={dados.valor_taxa_entrega}
            onChange={handleChangeText}
            startAdornment={
              <InputAdornment position="start">R$</InputAdornment>
            }
          />
        </FormControl>
        <Space />
        <FormControl fullWidth variant="filled">
          <TextField
            style={{ width: "100%" }}
            id="standard-multiline-flexible"
            name="tempo_medio_pedido"
            value={dados.tempo_medio_pedido}
            onChange={handleChangeText}
            label="Tempo médio do pedido"
            multiline
            variant="filled"
            rowsMax={4}
          />
        </FormControl>
        <Space />
        <Button variant="contained" color="primary" onClick={handleSalvar}>
          <p style={{ color: "white" }}>Salvar</p>
        </Button>
      </Container>
    </>
  );
};

export default Configuracoes;
