import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import Chip from "@material-ui/core/Chip";
// import Collapse from "@material-ui/core/Collapse";
// import Typography from "@material-ui/core/Typography";
// import CardActions from "@material-ui/core/CardActions";
// import clsx from "clsx";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
// import { theme } from "../../../styles/theme";
// import BrandCardHeader from "@mui-treasury/components/cardHeader/brand";
import CardHeader from "@material-ui/core/CardHeader";
import { Tooltip } from "@material-ui/core";
import {Link} from 'react-router-dom';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import Theme from "../../../styles/theme";
import EditIcon from "@material-ui/icons/Edit";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const style = makeStyles((Theme) => ({
  title: {
    fontSize: "16px"
  }
}));


function CardGrupo(props) {

  const classes = style();
  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card 
        style={{
          width: "550px",
          marginTop: "0.7rem",
          boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
        }}
        variant="outlined"
      >
        <CardHeader 
          action={
            <>
              <Tooltip title="Editar Grupo" arrow>
                <IconButton>
                <Link to={`/menu/grupo/${props.grupo.id}`}>
                  <EditIcon color="primary" />
                  </Link>
                </IconButton>
              </Tooltip>
            </>
          }
          title={
            <p className={classes.title}>
              <b>{props.grupo.id}</b> - {props.grupo.descricao}
            </p>
          }
        ></CardHeader>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CardGrupo;
