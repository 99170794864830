import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import Header from "../../../components/painel/headerPainel";
import { CntData, Container, ContDiv, BtnRecover, LblTitulo } from './style';
import api from "../../../services/api";
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CNPJMask from '../../../components/Masks/cnpjMask';

const PasswordRecovery = (props) => {

  const [cnpj, setCNPJ] = useState('')

  async function chkEmail() {
    let options = {
      headers: {
        cnpj: cnpj.replace(/[^0-9]/g, ''),
      }
    };

    console.log(options)

    await api.patch('/estabelecimento/resetpw','',options).then(res => {
      console.log(res)
      handleShow();
    })
    .catch(error => {
      console.log(error)
    })
  }

  async function changePw() {
    if (!cnpj) {
      handleShowErr();
    }
    else {
      await chkEmail();
    }
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showErr, setShowErr] = useState(false);
  const handleCloseErr = () => setShowErr(false);
  const handleShowErr = () => setShowErr(true);

  return (
    <>
      <Container>
        <Header goBack titulo="Recuperação de Senha" />
        <CntData>
          <ContDiv>
            <label> Insira o CNPJ cadastrados</label>
            <LblTitulo>CNPJ</LblTitulo>
            <CNPJMask
              handlechange={event => setCNPJ(event.target.value)}
            />
            <BtnRecover
              type="submit"
              title='Entrar'
              onClick={changePw}  >
              Recuperar
            </BtnRecover>
          </ContDiv>
        </CntData>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Solicitação efetuada!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Verifique sua caixa de email!
        </Modal.Body>
        <Modal.Footer>
          <a href={'/'}>
            <Button variant="secondary" onClick={handleClose}>
              Ok
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
      <Modal show={showErr} onHide={handleCloseErr}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body> É obrigatório o preenchimento do CNPJ! </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErr}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default PasswordRecovery;