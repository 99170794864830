import { createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F37021",
    },
    secondary: {
      main: "#FFFFFF",
    }
  },
});

export default theme;