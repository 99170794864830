import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import Button from "@material-ui/core/Button";
//import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
//import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
//import { encode, decode } from "base64-arraybuffer";
import api from "../../../services/api";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
//import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// function MensagemExcluir(props) {
//   const [open, setOpen] = React.useState(false);

//   const confirmDelete = () => {
//     async function deleteData(){
//       await api.patch(`/produtos/${props.chave}/status`).then((response) => {
//         console.log(response);
//       });
//     }
//     deleteData();
//     handleClose();
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
  
//   return (
//     <div>
//       <DeleteForeverIcon color="primary" onClick={handleClickOpen} />
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Deseja desativar o produto?"}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Caso você confirme essa operação, o item será desativado.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Não
//           </Button>
//           <Button onClick={confirmDelete} color="primary" autoFocus>
//             Sim
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

const useStyles = makeStyles({
  root: {
    width: "550px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  displayContent: {
    display: "inline",
  },
  contentWrapper: {
    marginTop: "12px",
  },
  inactive : {
    width: "550px",
    opacity: ".6"
  },
});

// Função de Card do Produto

function CardProduto({ titulo, imagem = "", ativo, produtoid}) {
  const classes = useStyles(); //
  const [img, setImg] = useState("");
  let Buffer = require('buffer/').Buffer

  const [status, setStatus] = useState(ativo);

  const altenarSwitch = () => {
    setStatus(!status);
    async function deleteData(){
      await api.patch(`/produto/${produtoid}/status`).then((response) => {
      });
    }
    deleteData()
  }

  useEffect(() => tratarImagem(imagem));
  async function tratarImagem(arrayBuffer) {
    let base64 = Buffer.from(imagem).toString("base64");
    setImg(atob(base64));
  }

  return (
    <div className={classes.contentWrapper}>
      <Card  variant="outlined" className={ !status ? classes.inactive : classes.root} >
        <CardHeader
          avatar={
            imagem !== "" ? (
              <Avatar
                aria-label="recipe"
                variant="rounded"
                className={classes.avatar}
                src={img}
              ></Avatar>
            ) : (
              <Avatar
                aria-label="recipe"
                variant="rounded"
                className={classes.avatar}
              >
                {titulo.substring(0, 1)}
              </Avatar>
            )
          }
          action={
            <>
              <FormControlLabel
                control={
                  <Switch
                    name="ativo"
                    color="primary"
                    checked={status}
                    onChange={altenarSwitch}
                  />
                }
                label = {!status ? "Inativo" : "Ativo"}
              />
              
              <Tooltip title="Editar Produto" arrow>
                <IconButton>
                <Link to={`menu/produto/${produtoid}`}>
                  <EditIcon color="primary" />
                  </Link>
                </IconButton>
              </Tooltip>
            </>
          }
          title={titulo}
        />
      </Card>
    </div>
  );
}

export default CardProduto;
