import { TextField } from "@material-ui/core";
import React from "react";
import InputMask from "react-input-mask";

const GerCNPJMask = ({valor, handlechange, onblur, err, helpertext, classname}) => {
 return( 
  <InputMask
    error={err}    
    mask="99.999.999/9999-99"   
    value={valor}      
    onChange={handlechange}
    onBlur={onblur}
    disabled

  >
    {() => 
      <TextField      
        id="cnpjA"
        name="cnpj" 
        helperText={helpertext}          
        className={classname}    
        variant="outlined"  
        size="small"        
      />
    }
  </InputMask>
  )
};
export default GerCNPJMask;