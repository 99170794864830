import { toast } from "react-toastify";
import * as t from "../constants/actionsTypes";
import api from "../services/api";

const setLoginState = (loginData) => {
  return {
    type: t.SET_LOGIN_STATE,
    payload: loginData,
    fantasia: loginData.FANTASIA,
    token: loginData.token,
    isLogged: true,
  };
};

export const postLogin = (logindata) => {
  const { login, senha } = logindata;

  return async (dispatch) => {
    return await api.post("/autenticacao", {
      login,
      senha,
    })
      .then((res) => res.data)
      .then((json) => {
        if (json.token) {
          localStorage.setItem("token", json.token);
          localStorage.setItem("refresh_token", json.refresh_token);
          localStorage.setItem("cnpj", login);
          dispatch(setLoginState({ ...json }));
          window.location.href = '/menu';
        } else {
          alert(
            "Login Failed - Sem Token",
            "Username or Password is incorrect"
          );
          window.location.href = '/painel';
        }
      })
      .catch((err) => {
        let error = null;
        error = err.toString().substr(err.toString.length - 3, 3);

        if (error === '401') {
          toast.error('Dados incorretos!',
            {
              position: "top-right",
              autoClose: 3600,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        }

        if (error === '403') {
          toast.error('Acesso bloqueado! Entre em contato com o suporte!',
            {
              position: "top-right",
              autoClose: 3600,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        }

        if (error === '404') {
          toast.error('Faltam dados para conseguir o acesso!',
            {
              position: "top-right",
              autoClose: 3600,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        }

        if (error === '500') {
          toast.error('Dados incorretos!',
            {
              position: "top-right",
              autoClose: 3600,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        }
      });
  };
};

export const logout = (dispatch) => {
  return (dispatch) => {
    dispatch(setLoginState({ token: "", isLogged: false, payload: "" }));
    localStorage.removeItem("token");
  };
};
