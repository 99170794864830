import React, { useState, /* setState,*/ useEffect } from "react";
import api from "../../../services/api";
import Header from "../../../components/painel/headerPainel";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
// import AddBoxIcon from "@material-ui/icons/AddBox";
// import notFound from "../../../assets/images/not_found.svg";
import { Box } from "@material-ui/core";
import CardPedido from "../../../components/painel/cardPedido";
// import IconButton from "@material-ui/core/IconButton";
// import SortIcon from '@material-ui/icons/Sort';
// import {moment} from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

function Pedidos() {
  const [pedidos, setPedidos] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [rangeDate, setRangeDate] = useState([new Date(), new Date()]);

  useEffect(() => {
    async function fetchData(){
      await api.get("/pedidos").then((response) => {
        setPedidos(response.data.pedidos.sort((a,b) => b.numero_pedido - a.numero_pedido));
        console.log(response.data.pedidos);
      });
    }
    fetchData();
  }, []);

  const onSelectNewDate = (value) => {
    setRangeDate(value);
  }

  return (
    <>
      <Header goBack />
      <Container maxWidth="sm">
        <br />
        <Box
          display="inline"
          width="498px"
          alignItems="center"
          justifyContent="center"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography color="primary" variant="h6">
                <b>Meus Pedidos</b>
              </Typography>
            </div>
           
            <div>
            </div>
            <DateRangePicker
              onChange={onSelectNewDate}
              value={rangeDate}
              locale="pt-BR"
              rangeDivider="até"
              />
          </div>
        </Box>
        <Divider />
            {pedidos.length > 0 && 
            pedidos.map(pedido => <CardPedido pedido={pedido} />)
            }      
      </Container>
    </>
  );
}

export default Pedidos;
