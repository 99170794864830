import React from 'react';
import './App.css';
import Routes from './routers/route';
import api from './services/api';

import { ToastContainer, Flip } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// Tempo para nova requisição de refresh token
setInterval(async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  const cnpj         = localStorage.getItem('cnpj');
  if (window.location.href !== window.location.protocol + '//' + window.location.host + '/painel') {
    await api.post('autenticacao/refresh', { refresh_token: refreshToken}, {
      headers: { cnpj },
    })
    .then(response => {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      console.log(response.data)
    })
    .catch(() => {
      localStorage.clear();
      window.location.href = '/painel';
    })
  }
}, 600000);

function App() {
  return (   
    <>
      <Routes />
      <ToastContainer 
        position="top-right"
        autoClose={3600}
        hideProgressBar={false}
        newestOnTop={false}
        theme="colored"
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Flip}
      />
    </>
  );
}

export default App;
