import React, {useEffect} from "react";
import {AppBar, Toolbar, IconButton} from '@material-ui/core';
import {connect} from 'react-redux';
import { createMuiTheme, MuiThemeProvider, makeStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import {useHistory} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F37021",
    },
    secondary: {
      main: "#FFFFFF",
    }
  },
});

const useStyles = makeStyles({
  title:{
    textAlign: "center",
    width: "100%"
  }
});



function Header({dispatch, titulo, menuShowing = false, goBack = false}) {
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    
  });

  function openMenu(){
    dispatch({type: 'TOGGLE_MENU', operation: true});
  }


  return (
    <MuiThemeProvider theme={theme}>
    <AppBar color="primary" position="static">

      <Toolbar>

      {goBack &&  (<IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => history.goBack() }
        >
        <ArrowBackIcon color="secondary"/>
        </IconButton>)
      }

      {menuShowing && (  <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => openMenu() }
        >
        <MenuIcon color="secondary"/>
        </IconButton>)}  

        <Typography className={classes.title} variant="h6" color="secondary" noWrap>
            {titulo}
        </Typography>
      </Toolbar>
    </AppBar>
    </MuiThemeProvider>
  );
} 

const mapStateToProps = (state) => {
  return{
    isOpen: state.panelReducer.menuIsOpen,
  }
}


export default connect(mapStateToProps, null)(Header); 


