import React from "react";
import InputMask from "react-input-mask";

const InputCNPJ = ({ cnpj, name, id, placeholder, onChange }) => {

 return( 
      <div className="group">      
        <InputMask
          mask="99.999.999/9999-99"
          maskChar="_"
          required
          id={id}
          name={name}
          value={cnpj}
          placeholder={placeholder}
          onChange={onChange}
        />   
      </div>   
  )
};

export default InputCNPJ;