import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Header from "../../../components/painel/headerPainel";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
//import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import api from "../../../services/api";
import { toast } from "react-toastify";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ProductInfo(props) {
  const { id } = useParams();

  const [controladores, setControladores] = useState({
    ativo: true,
    aceitaDescricao: false,
  });

  const [messageInformation, setMessageInformation] = useState({
    open: false,
    message: "",
    type: "",
  });

  const [informacoes, setInformacoes] = useState({
    itemName: "",
    itemDesc: "",
    grupo: "",
    grupoDescricao: "",
    price: "0.00",
    imagem: "",
    //idIntegracao: "",
  });

  //const [isEditing, setEditing] = useState(id !== null);

  const [erros, setErros] = useState({
    itemName: false,
    itemDesc: false,
    grupo: false,
    price: false,
  });

  const [grupos, setGrupos] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get("/grupos");
      setGrupos(response.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log(gruposMenuItems);
  });

  const gruposMenuItems = grupos.map((grupo) => (
    <MenuItem value={grupo.id}>{grupo.descricao}</MenuItem>
  ));

  const handleOpenClickAviso = (mensagem, tipo) => {
    setMessageInformation({ open: true, message: mensagem, type: tipo });
  };

  const closeAviso = () => {
    setMessageInformation({ ...messageInformation, open: false });
  };

  const handleCloseClickAviso = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeAviso();
  };

  function tratarBlobDescricao(descricao) {
    return Buffer.from(descricao).toString("utf8");
  }

  function tratarBlobImagem(descricao) {
    const base64 = Buffer.from(descricao).toString("base64");
    return atob(base64);
  }

  useEffect(() => {
    async function fetchData() {
      await api.get("/estabelecimento")
        .then(async (estabelecimento) => {
          const options = {
            headers: {
              cnpj: estabelecimento.data.cnpj,
            }
          }
          await api.get(`/produto/${id}`, options)
            .then((response) => {
              const newInformacoes = {
                itemName: response.data.nome,
                itemDesc: tratarBlobDescricao(response.data.descricao),
                grupo: response.data.id_grupo,
                price: response.data.preco,
                imagem: tratarBlobImagem(response.data.foto),
                // idIntegracao: response.data.ID_INTEGRACAO,
              }

              const newControladores = {
                ativo: response.data.ativo,
                aceitaDescricao: response.data.flag_observacao,
              }

              setInformacoes(newInformacoes);
              setControladores(newControladores);
            })
        })
        .catch(() => {
          toast.error('Erro ao buscar informações do produto!')
        })
    }


    if (id) {
      fetchData();
    }
  }, [id]);

  // const inputPreco = () => {
  //   return (
  //     <FormControl fullWidth variant="outlined">
  //       <InputLabel htmlFor="outlined-adornment-amount">Preço</InputLabel>
  //       <OutlinedInput
  //         id="outlined-adornment-amount"
  //         error={erros.price}
  //         value={informacoes.price}
  //         onBlur={(e) => controllerError(e)}
  //         onChange={(e) => HandleChangeInformations(e)}
  //         startAdornment={<InputAdornment position="start">R$</InputAdornment>}
  //         labelWidth={60}
  //       />
  //     </FormControl>
  //   );
  // };

  function controllerError(event) {
    let changed = false;

    if (event.target.value.length === 0) {
      changed = true;
    }

    setErros({ ...erros, [event.target.name]: changed });
  }

  function verifyChanges(value, nameOfTarget) {

    if (value === "") {
      setErros({ ...erros, [nameOfTarget]: true });
    } else {
      setErros({ ...erros, [nameOfTarget]: false });
    }
  }

  function HandleChangeInformations(event) {
    setInformacoes({ ...informacoes, [event.target.name]: event.target.value });
    verifyChanges(event.target.value, event.target.name);
  }

  function HandleChange(event) {
    setControladores({
      ...controladores,
      [event.target.name]: event.target.checked,
    });
  }

  function existemCamposVazios() {
    if (!informacoes.itemName) {
      document.getElementsByName('itemName')[0].focus();
    }

    if (!informacoes.itemDesc) {
      document.getElementsByName('itemDesc')[0].focus();
    }
    
    if (!informacoes.grupo) {
      document.getElementById('demo-simple-select-outlined itemGrupo').focus();
    }

    return (!informacoes.itemName || !informacoes.itemDesc || !informacoes.grupo);
  }

  function tryPostProduct(e) {
    if (existemCamposVazios()) {
      handleOpenClickAviso("Preencha todos os campos!", "error");
    } else {
      if (controladores.ativo && !informacoes.price) {
        handleOpenClickAviso("Não é possível salvar um produto ativo sem preço!", "error");
        return;
      }

      // const SelectedGroup = grupos.filter(group => group.id === informacoes.grupo);

      if (typeof id === 'undefined') {
        api
          .post("/produto", {
            nome: informacoes.itemName,
            descricao: informacoes.itemDesc,
            foto: informacoes.imagem,
            flag_observacao: controladores.aceitaDescricao,
            id_grupo: informacoes.grupo,
            preco: informacoes.price ?? '0.00',
            ativo: controladores.ativo ?? false,
          })
          .then((response) => {
            console.log(response);
            handleOpenClickAviso("Produto adicionado com sucesso!", "success");
            setInformacoes({
              itemName: "",
              itemDesc: "",
              grupo: "",
              grupoDescricao: "",
              price: "0.00",
              imagem: "",
            })
            setControladores({
              ativo: true,
              aceitaDescricao: false,
            })
          })
          .catch((error) => {
            if ((error.status = 409)) {
              handleOpenClickAviso("Erro ao inserir produto", "warning");
            } else {
              handleOpenClickAviso(
                "Erro interno ao adicionar o produto",
                "error"
              );
            }
          });
      } else {
        api
          .put(`/produto/${id}`, {
            nome: informacoes.itemName,
            descricao: informacoes.itemDesc,
            foto: informacoes.imagem,
            flag_observacao: controladores.aceitaDescricao,
            id_grupo: informacoes.grupo,
            preco: informacoes.price ?? '0.00',
            ativo: controladores.ativo ?? false,
            //id_integracao: informacoes.idIntegracao,
          })
          .then((response) => {
            console.log(response);
            handleOpenClickAviso("Produto alterado com sucesso!", "success");
          })
          .catch((error) => {
            if ((error.status = 409)) {
              handleOpenClickAviso("ID Integração duplicado, verifique!", "warning");
            } else {
              handleOpenClickAviso(
                "Erro interno ao atualizar o produto",
                "error"
              );
            }
          });
      }
    }
  }

  function handleUpdateSelect(event) {
    setInformacoes({
      ...informacoes,
      grupo: event.target.value,
    });

  }

  function handleImageUploader(event) {
    let arquivo = event.target.files[0];
    let reader = new FileReader();

    if (arquivo.size > 5000000) {
      toast.error("Imagem ultrapassou o limite de tamanho máximo (5MB)");
    } else {
      reader.onloadend = () => {
        setInformacoes({
          ...informacoes,
          imagem: reader.result,
        });
      };
  
      reader.readAsDataURL(arquivo);
    }
  }

  const Space = () => <div style={{ height: "0.75rem" }}></div>;

  return (
    <>
      <Header goBack />
      <br />
      <Container
        maxWidth="sm"
        style={{
          height: "40em",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          padding: "1rem",
        }}
      >
        <h3
          color="#f05a22"
          style={{ textDecoration: "bold", color: "#f05a22" }}
        >
          Dados do Produto
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div style={{ display: "flex" }}>
            <label for="file-input">
              <Avatar
                style={{
                  height: "70px",
                  width: "70px",
                  marginLeft: "8px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
                aria-label="recipe"
                variant="rounded"
                src={informacoes.imagem}
              ></Avatar>
            </label>
            <input
              id="file-input"
              type="file"
              onChange={(event) => handleImageUploader(event)}
              style={{ display: "none" }}
              accept=".png, .jpg, .jpeg"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "50px",
            }}
          >
            {/* <FormControlLabel
              control={
                <Switch
                  checked={controladores.aceitaDescricao}
                  name="aceitaDescricao"
                  onClick={(e) => HandleChange(e)}
                  color="primary"
                />
              }
              label="Aceita observação"
            /> */}

            <FormControlLabel
              control={
                <Switch
                  checked={controladores.ativo}
                  name="ativo"
                  onClick={(e) => HandleChange(e)}
                  color="primary"
                />
              }
              label={controladores.ativo ? 'Ativo' : 'Inativo'}
            />
          </div>
        </div>
        <Space />
        <div
          style={{ display: "flex", flexDirection: "column", margin: "10px" }}
        >
          <div>
            <TextField
              error={erros.itemName}
              style={{ width: "100%" }}
              id="standard-multiline-flexible"
              label="Nome do Item"
              name="itemName"
              helperText={erros.itemName && "Campo obrigatório"}
              onChange={(e) => HandleChangeInformations(e)}
              onBlur={(e) => controllerError(e)}
              multiline
              value={informacoes.itemName}
              variant="outlined"
              required
              rowsMax={4}
            />
          </div>
          <Space />
          <Snackbar
            open={messageInformation.open}
            autoHideDuration={6000}
            onClose={handleCloseClickAviso}
          >
            <Alert
              onClose={handleCloseClickAviso}
              severity={messageInformation.type}
            >
              {messageInformation.message}
            </Alert>
          </Snackbar>
          <div>
            <TextField
              style={{ width: "100%" }}
              id="outlined-multiline-static"
              label="Descrição do Item"
              name="itemDesc"
              onBlur={(e) => controllerError(e)}
              helperText={erros.itemDesc && "Campo obrigatório"}
              error={erros.itemDesc}
              multiline
              value={informacoes.itemDesc}
              rows={5}
              defaultValue=""
              onChange={(e) => HandleChangeInformations(e)}
              variant="outlined"
            />
          </div>
          <div>
            <Space />
            <FormControl variant="outlined" style={{ width: "100%" }} name="itemGrupo">
              <InputLabel id="demo-simple-select-outlined-label">
                Grupos
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined itemGrupo"
                label="Grupos"
                value={informacoes.grupo}
                name={informacoes.grupoDescricao}
                onChange={(e) => handleUpdateSelect(e)}
                
              >
                {gruposMenuItems}
              </Select>
            </FormControl>

            <Space />
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ width: "100%" }}>
                <CurrencyTextField
                  label="Preço"
                  fullWidth
                  variant="outlined"
                  value={informacoes.price}
                  currencySymbol="R$"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  onChange={(event, value) =>
                    setInformacoes({ ...informacoes, price: value })
                  }
                />
              </div>

              {/* 
              <div style={{ width: "50%", marginLeft: "0.3rem" }}>
                <TextField
                  style={{ width: "100%" }}
                  id="standard-multiline-flexible"
                  label="ID Integração"
                  name="idIntegracao"
                  value={informacoes.idIntegracao}
                  error={erros.grupo}
                  multiline
                  onBlur={(e) => controllerError(e)}
                  variant="outlined"
                  onChange={(e) => HandleChangeInformations(e)}
                  rowsMax={4}
                />
              </div> 
              */}
            </div>
          </div>
          <Space />
          <Button
            onClick={(e) => tryPostProduct(e)}
            variant="contained"
            color="primary"
          >
            <p style={{ color: "white" }}>Salvar Produto</p>
          </Button>
        </div>
      </Container>
    </>
  );
}

export default ProductInfo;
