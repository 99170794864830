import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// import toggleMenu from "../../../actions/panelActions";
import { connect } from "react-redux";
import {  /* Link, Redirect, */ useHistory } from "react-router-dom";
import { logout } from "../../../actions/loginActions";
import api from "../../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  list: {
    width: 250,
    height: "100%",
  },
  fullList: {
    width: "auto",
  },
  myText: {
    marginLeft: "1em",
    color: "#F37021",
  },
  blankWrapper: {
    height: "em",
    paddingLeft: "3px",
    paddingTop: "10px",
    marginBottom: "8px",
  },
  topMenu: {
    display: "flex",
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
});

function Menu({ isOpen, dispatch, nome }) {
  const classes = useStyles();

  const history = useHistory();

  const [fantasia, setFantasia] = useState("");

  useEffect(() => {
    async function getDados() {
      await api.get("/estabelecimento")
      .then((response) => {
        setFantasia(response.data.fantasia);
      })
      .catch((error) => {
        toast.warning('Erro ao trazer dados do estabelecimento!')
      })
    }
    getDados();
  }, []);


  async function itemSelected(item) {
    if (item === 2) {
      await dispatch(logout());
      dispatch({ type: "TOGGLE_MENU", operation: false });
      localStorage.setItem('token', '');
      history.push('/painel');
    } else if (item === 3) {
      dispatch({ type: "TOGGLE_MENU", operation: false });
      history.push('/menu');
    } else if (item === 4) {
      dispatch({ type: "TOGGLE_MENU", operation: false });
      history.push('/configuracoes');
    } else if (item === 5) {
      dispatch({ type: "TOGGLE_MENU", operation: false });
      history.push('/pedidos');
    } else if (item === 6) {
      dispatch({ type: "TOGGLE_MENU", operation: false });
      history.push('/clientes');
    } else if (item === 7) {
      dispatch({ type: "TOGGLE_MENU", operation: false });
      history.push('/menu/grupos');
    }
    else if (item === 8) {
      dispatch({ type: "TOGGLE_MENU", operation: false });
      history.push('/menu/gerenciamento');
    }
  }

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={() => dispatch({ type: "TOGGLE_MENU", operation: false })}
        onOpen={() => dispatch({ type: "TOGGLE_MENU", operation: false })}
      >
        <div
          className={classes.list}
          role="presentation"
          //onClick={() => dispatch(toggleMenu(false))}
          //onKeyDown={() => dispatch(toggleMenu(true))}>
        >
          <List>
            <div className={classes.blankWrapper}>
              <h4 className={classes.myText}>Olá,</h4>
              <h4 className={classes.myText}> { fantasia } </h4>
            </div>
            <Divider />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <ListItem button  onClick={() => itemSelected(3)} >
                  <ListItemText
                    primary="Produtos"                   
                  />
                </ListItem>
                {/*
                <ListItem button >
                  <ListItemText
                    primary="Pedidos"
                    onClick={() => itemSelected(5)}
                  />
                 </ListItem> 
                   
                <ListItem button>
                  <ListItemText
                    primary="Clientes"
                    onClick={() => itemSelected(6)}
                  />
                </ListItem>
                */} 
                            
                <ListItem button  onClick={() => itemSelected(7)} >
                  <ListItemText primary="Grupos"/>
                </ListItem>

                <ListItem button onClick={() => itemSelected(8)}>
                  <ListItemText
                    primary="Dados Empresa"/>
                </ListItem>

                {/*
                <ListItem button>
                  <ListItemText
                    primary="Configurações Gerais"
                    onClick={() => itemSelected(4)}
                  />
                </ListItem>
                */}
                <Divider />
              </div>

              <div style={{ display: "flex", alignItems: "flex-end"}}>
                <ListItem button  onClick={() => itemSelected(2)}>
                  <ListItemText
                    primary="Sair"               
                  />
                </ListItem>
              </div>
            </div>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
}

const ConnectedMenu = connect((state) => {
  return { isOpen: state.panelReducer.menuIsOpen, nome: "Dobes One" };
})(Menu);

export default ConnectedMenu;
