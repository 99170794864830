import { initialState } from '../store/initialState';
import * as t from '../constants/actionsTypes';

export const panelReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.TOGGLE_MENU:
      return {
        ...state,
        menuIsOpen: action.operation
      };
    case t.SET_LOGIN_STATE:
      return {
        ...state,
      }
    default: 
      return state;
  }

}