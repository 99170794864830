import { initialState } from '../store/initialState';
import * as t from '../constants/actionsTypes';

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.SET_LOGIN_STATE:
      return {
        ...state,
        ...action.payload,
        isLogged: true,
      };
    default:
      return state;
  }
};
