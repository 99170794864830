import React, { useState, useEffect } from 'react';
import Header from '../../../components/painel/headerPainel';
import TextField from '@material-ui/core/TextField';
import { Redirect, useHistory } from 'react-router-dom';
import Menu from '../../../components/painel/menuPainel';
import validaToken from '../../../helpers/validaToken';
import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  Link,
} from '@material-ui/core';
import {
  ContDiv,
  TituloDiv,
  ItensDiv,
  LineDivs,
  HeaderDiv,
  BottomDiv,
} from './style.js';
import axios from 'axios';
import api from '../../../services/api';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import GerCNPJMask from '../recovery/components/gerCnpjMask';
import GerCEPMask from '../recovery/components/gerCEPMask';
import GerFoneMask from '../recovery/components/gerFoneMask';
import { toast } from 'react-toastify';
import validator from 'validator';

function Gerenciamento() {
  const [infDados, setInfDados] = useState({
    cnpj: '',
    razao_social: '',
    fantasia: '',
    logo: '',
    cep: '',
    endereco_completo: '',
    endereco_nro: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    telefone: '',
    whatsapp: '',
    email: '',
    site: '',
    subdominio: '',
  });

  const [erros, setErros] = useState({
    razao_social: false,
    endereco_completo: false,
    endereco_nro: false,
    complemento: false,
    cep: false,
    bairro: false,
    cidade: false,
    estado: false,
    telefone: false,
    email: false,
  });

  const [password, setPass] = useState('');
  const [newPassword, setNewPass] = useState('');
  const [ConfirmationPW, setConfirmationPW] = useState('');
  const [showPW, setShowPw] = useState(false);
  const [showNewPW, setShowNewPw] = useState(false);
  const [showConfPW, setShowConfPw] = useState(false);

  useEffect(() => {
    async function getDados() {
      const response = await api.get('/estabelecimento');

      const dadosTratados = {
        ...response.data,
        logo: response.data.logo ? tratarBlobImagem(response.data.logo) : '',
      };

      setInfDados(dadosTratados);
    }
    getDados();
  }, []);

  async function getDataViaCep() {
    let cep = infDados.cep.replace(/[^0-9]/g, '');

    if (cep) {
      const response = await axios.get(
        'https:\\viacep.com.br/ws/' + cep + '/json/',
      );
      infDados.endereco_completo = response.data.logradouro;
      infDados.bairro = response.data.bairro;
      infDados.cidade = response.data.localidade;
      infDados.estado = response.data.uf;
      infDados.complemento = response.data.complemento;
    }

    setErros({ ...erros, btnSalvar: true });
    setNewInfo();
  }

  const setNewInfo = () => {
    setInfDados(infDados);
  };

  const handleClickShowPassword = () => setShowPw(!showPW);
  const handleMouseDownPassword = () => setShowPw(!showPW);

  const handleClickShowNewPassword = () => setShowNewPw(!showNewPW);
  const handleMouseDownNewPassword = () => setShowNewPw(!showNewPW);

  const handleClickShowConfPassword = () => setShowConfPw(!showConfPW);
  const handleMouseDownConfPassword = () => setShowConfPw(!showConfPW);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function verifyEmptyFields() {
    if(!infDados.razao_social){
      toast.error('Nome da empresa da empresa não pode estar vazio!');
      document.getElementsByName('razao_social')[0].focus();
      return;
    }
    if (!infDados.fantasia){
      toast.error('Nome fantasia não pode ser vazio!');
      document.getElementsByName('fantasia')[0].focus();
      return;
    }
    if (!infDados.cep){
      toast.error('CEP não pode ser vazio!');
      document.getElementsByName('cep')[0].focus();
      return;
    } else if (infDados.cep.length !== 8){
      toast.error('CEP inválido');
      document.getElementsByName('cep')[0].focus();
      return;  
    }

    if (!infDados.endereco_completo) {
      toast.error('Endereço não pode ser vazio!');
      document.getElementsByName('endereco_completo')[0].focus();
      return;
    }
    if (!infDados.endereco_nro) {
      toast.error('Número não pode ser vazio!');
      document.getElementsByName('numero')[0].focus();
      return;
    }
    if (!infDados.telefone) {
      toast.error('Telefone não pode ser vazio!');
      document.getElementsByName('telefone')[0].focus();
      return; 
    }
    if (!infDados.whatsapp) {
      toast.error('Whatsapp não pode ser vazio!');
      document.getElementsByName('whatsapp')[0].focus();
      return;
    }
    if (!infDados.email){
      toast.error('Email não pode ser vazio!');
      document.getElementsByName('email')[0].focus();
      return;
    } else if (!validator.isEmail(infDados.email)){
      toast.error('Email inválido!');
      document.getElementsByName('email')[0].focus();
      return;
    }
    
    return tryPostData();
  }

  function handleChange() {
    if (!password || !newPassword || !ConfirmationPW) {
      toast.error('Todos os campos são obrigatórios!', {
        position: 'top-right',
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (newPassword !== ConfirmationPW) {
        toast.error('Nova Senha e Confirmação não conferem!', {
          position: 'top-right',
          autoClose: 2400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        tryChangePass();
      }
    }
  }

  async function tryChangePass(e) {
    try {
      await api
        .patch('/estabelecimento/senha', {
          senha: password,
          nova_senha: newPassword,
        })
        .then((response) => {
          setPass('');
          setNewPass('');
          setConfirmationPW('');
          toast.success('Senha Alterada!', {
            position: 'top-right',
            autoClose: 2400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
        });
    } catch {
      toast.error('Senha atual incorreta!', {
        position: 'top-right',
        autoClose: 2400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function controllerError(event) {
    let changed = false;

    if (event.target.value.length === 0) {
      changed = true;
    }
    setErros({ ...erros, [event.target.name]: changed });
  }

  function HandleChangeInformations(event) {
    if (
      event.target.name === 'telefone' ||
      event.target.name === 'cep' ||
      event.target.name === 'whatsapp'
    )
      setInfDados({
        ...infDados,
        [event.target.name]: event.target.value.replace(/[^0-9]/g, ''),
      });
    else setInfDados({ ...infDados, [event.target.name]: event.target.value });

    verifyChanges(event.target.value, event.target.name);
  }

  function verifyChanges(value, nameOfTarget) {
    if (value === '') {
      setErros({ ...erros, [nameOfTarget]: true });
    } else {
      setErros({ ...erros, [nameOfTarget]: false });
    }
  }

  function handleImageUploader(event) {
    let arquivo = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = () => {
      setInfDados({
        ...infDados,
        logo: reader.result,
      });
    };
    reader.readAsDataURL(arquivo);
  }

  async function tryPostData(e) {
    await api
      .put('/estabelecimento', {
        razao_social: infDados.razao_social,
        logo: infDados.logo,
        fantasia: infDados.fantasia,
        cep: infDados.cep,
        endereco_completo: infDados.endereco_completo,
        endereco_nro: infDados.endereco_nro,
        complemento: infDados.complemento,
        bairro: infDados.bairro,
        cidade: infDados.cidade,
        estado: infDados.estado,
        telefone: infDados.telefone,
        whatsapp: infDados.whatsapp,
        email: infDados.email,
        site: infDados.site,
      })
      .then((response) => {
        if(response.status === 200) {
          toast.success('Os dados foram atualizados com sucesso!');
        } else {
          toast.error('Ocorreu um erro ao atualizar o cadastro!');
        }
      })
      .catch((err) => {
        toast.error('Ocorreu um erro ao atualizar o cadastro!');
      });
  }

  function tratarBlobImagem(descricao) {
    const base64 = Buffer.from(descricao).toString('base64');
    return atob(base64);
  }

  const history = useHistory();
  const handleClick = () => history.push('/');

  const handleChangeSenhas = (event, setData) => {
    let { value } = event.target;

    setData(value.replace(/[^a-zA-Z0-9!@#$%&*()]/g, ''));
  };

  return (
    <>
      {!validaToken() ? <Redirect to="/Painel" /> : null}
      <Header goBack />
      <Menu />
      <ContDiv>
        <div>
          <TituloDiv> Dados da Empresa </TituloDiv>
        </div>
        <div
          style={{
            overflow: 'auto',
            position: 'stick',
            width: '100%',
            height: '80%',
            display: 'block',
            marginTop: '1rem',
          }}
        >
          <ItensDiv>
            <HeaderDiv>
              <label htmlFor="file-input">
                <Avatar
                  style={{
                    height: '170px',
                    width: '150px',
                    marginTop: '5px',
                    cursor: 'pointer',
                  }}
                  aria-label="recipe"
                  variant="rounded"
                  src={infDados.logo}
                ></Avatar>
              </label>
              <input
                id="file-input"
                type="file"
                onChange={(event) => handleImageUploader(event)}
                style={{ display: 'none', cursor: 'pointer' }}
                accept=".png, .jpg, .jpeg"
              />

              <div className="headerGen">
                <div className="containerUrl">
                  <label>Minha URL: </label>
                  <Link
                    href={
                      process.env.REACT_APP_BASE_URL_CATALOGO +
                      infDados.subdominio
                    }
                    className="lnkCat"
                    target="_blank"
                  >
                    {process.env.REACT_APP_BASE_URL_CATALOGO +
                      infDados.subdominio}
                  </Link>
                </div>

                <GerCNPJMask
                  err={erros.cnpj}
                  id="txtCnpj"
                  label="CNPJ"
                  name="cnpj"
                  classname="txtCnpj"
                  valor={infDados.cnpj}
                  helpertext={erros.cnpj}
                  handlechange={(e) => HandleChangeInformations(e)}
                  onblur={(e) => controllerError(e)}
                />
              </div>
            </HeaderDiv>

            <LineDivs>
              <TextField
                error={erros.razao_social}
                id="standard-multiline-flexible"
                label="Nome da Empresa"
                name="razao_social"
                size="small"
                className="txtRazSocial"
                helperText={erros.razao_social && 'Campo obrigatório'}
                variant="outlined"
                required
                value={infDados.razao_social}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />

              <TextField
                id="standard-multiline-flexible"
                label="Nome Fantasia"
                name="fantasia"
                size="small"
                className="txtNomFantas"
                variant="outlined"
                value={infDados.fantasia}
                required
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />
            </LineDivs>

            <LineDivs style={{ marginTop: '4rem' }}>
              <GerCEPMask
                err={erros.cep}
                classname="txtCep"
                helpertext={erros.cep && 'Campo obrigatório'}
                variant="outlined"
                required
                valor={infDados.cep}
                handlechange={(e) => HandleChangeInformations(e)}
                onblur={(e) => controllerError(e)}
              />

              <Button
                variant="contained"
                color="primary"
                className="btnBuscar"
                onClick={(e) => getDataViaCep(e)}
              >
                Buscar{' '}
              </Button>

              <TextField
                error={erros.endereco_completo}
                id="standard-multiline-flexible"
                label="Endereço"
                className="txtEnder"
                name="endereco_completo"
                size="small"
                variant="outlined"
                value={infDados.endereco_completo}
                helperText={erros.endereco_completo && 'Campo obrigatório'}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />

              <TextField
                id="standard-multiline-flexible"
                label="Número"
                name="endereco_nro"
                className="txtNro"
                required
                size="small"
                value={infDados.endereco_nro}
                variant="outlined"
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 9 }}
              />

              <TextField
                id="standard-multiline-flexible"
                label="Complemento"
                name="complemento"
                className="txtCompl"
                size="small"
                variant="outlined"
                value={infDados.complemento}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />
            </LineDivs>

            <LineDivs style={{ marginTop: '3rem' }}>
              <TextField
                id="standard-multiline-flexible"
                label="Bairro"
                name="bairro"
                size="small"                
                className="txtBairro"
                variant="outlined"
                value={infDados.bairro}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />

              <TextField
                id="standard-multiline-flexible"
                className="txtCid"
                label="Cidade"
                name="cidade"
                size="small"      
                variant="outlined"
                value={infDados.cidade}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Estado"
                name="estado"
                size="small"
                variant="outlined"
                className="txtUf"                
                value={infDados.estado}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 2 }}
              />
            </LineDivs>

            <LineDivs style={{ marginTop: '3rem' }}>
              <GerFoneMask
                error={erros.telefone}
                lbl="Telefone"
                nameTxt="telefone"
                classname="txtTel"
                mask="(99) 9999-9999"
                helpertext={erros.telefone && 'Campo obrigatório'}
                valor={infDados.telefone}
                handlechange={(e) => HandleChangeInformations(e)}
                onblur={(e) => controllerError(e)}
              />

              <GerFoneMask
                lbl="Whatsapp"
                nameTxt="whatsapp"
                classname="txtWhats"
                mask="(99) 99999-9999"
                valor={infDados.whatsapp}
                handlechange={(e) => HandleChangeInformations(e)}
                onblur={(e) => controllerError(e)}
              />

              <TextField
                error={erros.email}
                className="txtEmail"
                id="standard-multiline-flexible"
                label="E-mail"
                name="email"
                size="small"
                variant="outlined"
                required
                helperText={erros.email && 'Campo obrigatório'}
                value={infDados.email}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />

              <TextField
                id="standard-multiline-flexible"
                label="Site"
                name="site"
                size="small"
                className="txtSite"
                variant="outlined"
                value={infDados.site}
                onChange={(e) => HandleChangeInformations(e)}
                onBlur={(e) => controllerError(e)}
                inputProps={{ maxLength: 100 }}
              />
            </LineDivs>
          </ItensDiv>
        </div>
        <BottomDiv>
          <Button
            onClick={handleClick}
            variant="contained"
            className="btnCancel"
          >
            {' '}
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="btnChangePw"
            onClick={handleShow}
          >
            Alterar Senha
          </Button>

          <Button
            variant="contained"
            color="primary"
            className="btnSave"
            onClick={(e) => verifyEmptyFields(e)}
          >
            {' '}
            Salvar
          </Button>
        </BottomDiv>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Alterar Senha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Digite Aqui sua nova senha:
            <label style={{ display: 'flex', margin: '0', marginTop: '.7rem' }}>
              Senha Atual:
            </label>
            <TextField
              id="pw"
              style={{ display: 'flex' }}
              name="senha"
              size="small"
              variant="outlined"
              value={password}
              onChange={(e) => handleChangeSenhas(e, setPass)}
              type={showPW ? 'text' : 'password'}
              inputProps={{
                maxLength: 30,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPW ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <label style={{ display: 'flex', margin: '0', marginTop: '.7rem' }}>
              Nova Senha:
            </label>
            <TextField
              id="newPW"
              style={{ display: 'flex' }}
              name="senha"
              size="small"
              variant="outlined"
              value={newPassword}
              onChange={(e) => handleChangeSenhas(e, setNewPass)}
              type={showNewPW ? 'text' : 'password'}
              inputProps={{
                maxLength: 30,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                    >
                      {showNewPW ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <label style={{ display: 'flex', margin: '0', marginTop: '.7rem' }}>
              Confirme Nova Senha:
            </label>
            <TextField
              id="confPw"
              style={{ display: 'flex' }}
              name="senha"
              size="small"
              variant="outlined"
              value={ConfirmationPW}
              onChange={(e) => handleChangeSenhas(e, setConfirmationPW)}
              type={showConfPW ? 'text' : 'password'}
              inputProps={{
                maxLength: 30,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfPassword}
                      onMouseDown={handleMouseDownConfPassword}
                    >
                      {showConfPW ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleChange}>
              Alterar
            </Button>
          </Modal.Footer>
        </Modal>
      </ContDiv>
    </>
  );
}

export default Gerenciamento;
