//import thunkMiddleware from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { panelReducer } from '../reducers/panel';
import { loginReducer } from '../reducers/login'
import thunk from "redux-thunk";
import logger from "redux-logger";
//import { storePanel } from '../store/index';

const rootReducer = combineReducers({
  panelReducer: panelReducer,
  loginReducer: loginReducer,
});

const middlewares = [thunk, logger];

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;