import styled from 'styled-components';

export const ContDiv = styled.div`
 
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  position: fixed;
  overflow: auto;
  border: .2px solid pink;

  div{
    ::-webkit-scrollbar {
    width: 0px;
  }
  
}


`;

export const TituloDiv = styled.div`
color: #f05a22;
text-align: center;
font-weight: 500;
width: 100%;
line-height: 2.5rem;
height: 3vh;
font-size: 28px;
margin-top: 1rem;
margin-bottom: 1rem;


`;

export const ItensDiv = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column; 
  `;

export const ImageInput = styled.input`
  display: none;
`;

export const LineDivs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //border: .2px solid red;
  width: 100% - 1.25rem;
  margin: 1.25rem 1rem 0rem 1rem; 


  .txtRazSocial{
    width: 100%;
    line-height: 2.5rem; 
    height: 20px;
  }

  .txtNomFantas{
    width: 100%;
    line-height: 2.5rem;
    height: 20px; 
    margin-left: 1rem;
  }

  .txtCep{
    width: 50%; 
    line-height: 2.5rem;
    height: 20px;
  }

  .btnBuscar{
    width: 20%;
    color: white;
    font-weight: 500;
    margin-left: .5rem;              
  }

  .txtEnder{
    width: 100%;
    height: 20px;
    margin-left:.5rem;
  }

  .txtNro{
    width: 35%;
    line-height :2.5rem; 
    height: 20px; 
    margin-left: 1rem;
  }

  .txtCompl{
      width: 100%; 
      line-height: 2.5rem; 
      height: 20px; 
      margin-left: 1rem;
    }

  .txtCid{
    width: 100%;
    line-height: 2.5rem; 
    height: 20px; 
    margin-left: 1rem;
   }

  .txtUf{    
    width: 100%; 
    line-height: 2.5rem; 
    height: 20px; 
    margin-left: 1rem;
  }

  .txtTel{
    width: 32%; 
    line-height: 2.5rem; 
    height: 20px;
  }

  .txtWhats{
    width: 32%; 
    line-height: 2.5rem;
    height: 20px;
    left: 1rem;
  }
  .txtEmail{
    width: 70%; 
    line-height: 2.5rem;
    height: 20px;
    margin-left: 2rem;
  }

  .txtBairro{
    width: 100%;
    line-height: 2.5rem;
    height: 20px; 
  }

  .txtSite{
    width: 70%;
    line-height: 2.5rem;
    height: 20px; 
    margin-left: 1rem;
  }

  @media screen and (max-width: 800px){      
    flex-direction: column;   
    margin-top: 0;
    justify-content: center;
    width: (100% - 5rem);

    .txtRazSocial{
      width: 100%;
      line-height: 2.5rem; 
      height: 20px;
    }
    .txtNomFantas{
    width: 100%;
    line-height: 2.5rem;
    height: 20px;    
    margin-left: 0;
    margin-top:3rem;
  }

  .txtCep{
    width: 70%;
    margin-bottom:1rem;
    margin-top: -1rem;
  }

  .btnBuscar{
    width: 30%;
    color: white;
    font-weight: 500;
    margin-left: .5rem;     
    left:70% ;
    top: -2.25rem;
    line-height: 2;
  }

  .txtEnder{
    width: 100%;
    height: 20px;
    margin-left:0;
  }

  .txtBairro{
    width: 100%;
    line-height: 2.5rem;
    height: 20px; 
    margin-top: -1rem;
    margin-bottom:1rem;
  }

  .txtNro{
    width: 30%;  
    margin-left:0;
    margin-top:3rem;   
  }

  .txtCompl{
    width: 68%;  
    left: 32%;  
    margin:0;
    top: -1.25rem;      
  }

  .txtCid{
    width: 70%;
    margin-left: 0;
    margin-top: 2rem;
  }

  .txtUf{
    width: 28%;     
    left: 67.5%;
    top: -1.25rem;
  }

  .txtTel{     
    width: 49%;
    margin-top:-1rem;
  }

  .txtWhats{
    width: 49%; 
    left:51%;
    top: -1.25rem;
  }

  .txtEmail{
    width: 49%; 
    margin-top:2rem;
    margin-left: 0;
  }

  .txtSite{
    width: 49%; 
    margin-left: 51%;
    top: -1.25rem;
  }
}
  
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}    
`

export const DataInput = styled.input`
  line-height: 2rem;  
  padding-left: 1.25rem;
  :last-child{
      margin-left: 0;
    }
`;

export const HeaderDiv = styled.div`
  display: flex;  
  margin: 1rem 1rem 0rem;
  justify-content: space-between; 
  flex-direction: row;
  
  .headerGen{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30%;
    right: 0;
    float: right;

      

    .txtCnpj{
      align-self: flex-end;
    }

    .containerUrl{
      display: flex;    
      flex-direction: row;
      justify-content: flex-end;
      text-align: center;

      .lnkCat{
        margin-left: .25rem;
        color: black;
      }

    }    
  }

 
  @media screen and (max-width: 800px){   
    margin-bottom: 1rem;
    .txtCnpj{
     width: 45%;
     text-align: center;   
    }
  }
`;



export const BottomDiv = styled.div`
  display: flex;
  justify-content: space-between; 
  position:sticky; 
  bottom:0;
  width:100%; 
  margin-bottom: 3.5rem; 
  color:transparent;
  min-height: 40px;
  margin-top: 1rem;
  

  .btnCancel{
  width: 10%; 
  color: white;
  font-weight: 500; 
  margin: 0;
  background-color: red;
  left:2rem;
  margin-bottom: 1rem;
  }

  .btnSave{
    width: 10%;
    color: white;
    font-weight: 500;
    margin: 0;
    right:2rem;   
    margin-bottom: 1rem;
  }

  .btnChangePw{
    width: 15%;
    color: white;
    font-weight: 500;
    margin: 0;
    right:2rem;   
    margin-bottom: 1rem;
    background-color: #57585A;
  }

  @media screen and (max-width: 800px){   
    display: flex;
    justify-content: space-evenly; 
    width: 100%;
    margin-top:2rem;    
    margin-bottom: 3.5rem; 
    color:transparent;
    min-height: 40px;

    .btnCancel{
      width: 40%;
      left:0;
    }
    
    .btnSave{
      width: 40%;
      left:0;     
    }
  }
`
