import styled from 'styled-components';
import fundo from '../../../assets/images/fundo.svg';

export const Container = styled.div`
  background: #f79d68;
  background-image: url(${fundo});
  background-position: center;
  background-repeat: none;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
`;

export const Formulario = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  width: 27rem;
  height: 38rem;
  margin: auto auto;
  background-color: var(--menu-color);
  border-radius: 1rem;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);  
`;

export const Cabecalho = styled.div`
  grid-column: 1/1;
  grid-row: 1/2;
  text-align: center;
  img { 
      margin: 2rem auto;
      text-align: center;
      width: 8.6rem;
    }
`

export const TextAlign = styled.div`
  text-align: start;
  margin: 0.6rem 3.8rem;
  h3 {
    font-weight: bold;
    color: var(--fontColorWhite);
    font-size: 1.4rem;
  }
  h6 {
    margin: 0.6rem auto;
    font-weight: bold;
    color: var(--fontColorWhite);
    font-size: 0.7rem;
  }   
`

export const Body = styled.div`
  grid-column: 1/1;
  grid-row: 2/3;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5rem;
  align-items: center;

  button {
    margin-top: 1rem;
    padding: 0.5rem;
    width: 75%;
    margin: 1rem auto;
    border-radius: 0.4rem;
    background: var(--primary-color);
    transition: filter 0.2s;
    color: #ffffff;
    font-weight: 700;
    &:hover {
      filter: brightness(0.9);
    }
  }
`

export const Footer = styled.div`
  grid-column: 1/1;
  grid-row: 3/4;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  h6{
    color: var(--fontColorWhite);
    font-size: 0.5rem;

    a { 
    color: var(--fontColorWhite); 
    text-decoration: none;
    margin: auto 0;
    }
  }  
`

export const FormContent = styled.div`
  display: -ms-flexbox;
  width: 90%;
  justify-content: space-between;
  .icon {
    padding: 0.64rem 0.1rem;
    border-radius: 0.5rem 0 0 0.5rem;
    background: var(--shadow-color);
    min-width: 3rem;
    text-align: center;
  }

  input { 
    border-bottom: 1px solid var(--fontColorWhite);
    border-right: none;
    border-top: none;
    border-left: none;
    padding: 0.4rem 0.1rem;
    width: 85%;
    background-color: transparent;
    margin: auto 1.6rem;
    color: var(--fontColorWhite);
    &:focus{
      border-bottom: 1px solid var(--primary-color);
      outline: none;
    }

    ::-ms-reveal {
      filter: invert(100%);
    }
  }
`;

export const SuportDiv = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-between;
  font-size: 0.86rem;
  a { 
    color: var(--fontColorWhite); 
    text-decoration: none;
    margin: auto 0;
  }
`;

export const Lembrarme = styled.div`
  display: flex;
  font-size: 0.8rem;
  justify-content: start;
  input {
    display: inline-block;
    margin: auto 0.7rem auto 0;
  }
  label {
    color: var(--fontColorWhite);
    margin: auto;
  }
`;