import { TextField } from "@material-ui/core";
import React from "react";
import InputMask from "react-input-mask";

const GerFoneMask = ({mask, valor, handlechange, onblur, err, helpertext, classname, lbl, nameTxt }) => {
 return(  
  <InputMask    
    mask={mask}  
    beforeMaskedValueChange = ""
    maskPlaceholder={null}
    onChange={handlechange}   
    value={valor}       
    onBlur={onblur}
  >
    {() => 
      <TextField   
        error={err}    
        required
        id={nameTxt}
        name={nameTxt} 
        label={lbl}
        helperText={helpertext}          
        className={classname}    
        variant="outlined"  
        size="small"        
      />
    }
  </InputMask>
  )
};
export default GerFoneMask;