import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import PainelLogin from "../pages/painel/login";
import PanelHome from "../pages/painel/home";
import GlobalStyle from "../styles/GlobalStyles";
import { Provider } from "react-redux";
import theme from "../styles/theme";
import storePanel from "../store/index";
import productInfo from "../pages/painel/productInfo";
import Configuracoes from "../pages/painel/configuracoes";
import Pedidos from "../pages/painel/pedidos";
import Clientes from "../pages/painel/clientes";
import Grupos from "../pages/painel/grupos";
import AddGrupo from "../pages/painel/addGrupo";
import EditGrupo from "../pages/painel/editGrupo";
import Gerenciamento from "../pages/painel/gerenciamento";
import PasswordRecovery from "../pages/painel/recovery";
import { Login } from "../components/Login";


const Routes = () => {

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Provider store={storePanel}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Redirect exact from="/" to="/painel" />
            <Route exact path={`/painel`}                component={PainelLogin} />,
            <Route exact path={`/menu`}                  component={PanelHome} />,
            <Route exact path={`/configuracoes`}         component={Configuracoes} />,
            <Route exact path={`/pedidos`}               component={Pedidos} />,
            <Route exact path={`/clientes`}              component={Clientes} />,
            <Route exact path={`/menu/produto/:id?`}     component={productInfo} />,
            <Route exact path={`/menu/grupos`}           component={Grupos} />,
            <Route exact path={`/menu/grupo/:id?`}       component={EditGrupo} />,
            <Route exact path={`/teste`}                 component={Login} />,
            <Route exact path={`/menu/grupos/adicionar`} component={AddGrupo} />, 
            <Route exact path={`/menu/gerenciamento`}    component={Gerenciamento} />,
            <Route exact path={`/recovery`}         component={PasswordRecovery} />,
          </Switch>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default Routes;
