import React, { useState, useEffect } from "react";
import Header from "../../../components/painel/headerPainel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "../../../components/painel/menuPainel";
import Container from "@material-ui/core/Container";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Link, Redirect } from "react-router-dom";
import CardGrupo from "../../../components/painel/cardGrupo";
import validaToken from "../../../helpers/validaToken";
import api from "../../../services/api";

function Grupos() {
  const [grupos, setGrupos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      api.get("/grupos").then((response) => {
        setGrupos(response.data);
        setLoading(false);
      });
    }
    fetchData();
  }, []);

  return (
    <>
      {!validaToken() ? <Redirect to="/Painel" /> : null}
      <Header goBack />
      <Menu />

      <Container maxWidth="sm">
        <br />
        <Box
          display="inline"
          width="498px"
          alignItems="center"
          justifyContent="center"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography color="primary" variant="h6">
                <b>Meus Grupos</b>
              </Typography>
            </div>
            <div>
              <Link to={'/menu/grupos/adicionar'}>
                {" "}
                <AddBoxIcon color="primary" />
              </Link>
            </div>
          </div>
        </Box>

        <Divider />
        {loading && (
          <center>
            <CircularProgress />
          </center>
        )}
        {grupos && grupos.map((grupo) => <CardGrupo grupo={grupo} />)}
      </Container>
    </>
  );
}

export default Grupos;
