import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root{
    --primary-color: #F36E21;
    --nav-color: #D1D2D4;
    --menu-color: #231F20;
    --background-color: #F9F9F9;
    --greyLight: #f2f5fa;
    --greyDark: #e7eaf4;
    --greyLight: #57585A;
    --purpleDark: #252234;
    --neonGreen: #6eb81b;
    --baseColor: #c3c3c363;
    --fontColor: #57585A;
    --fontColorWhite: #FFFFFF;
    --error: #EE1D23;
      /*
        #231F20
        #57585A
        #A8A9AD
        #D1D2D4
        #F36E21
        #F7931D
        #EE1D23
        #B00D15
      */
    
  }

  body{
    background-color: var(--background-color);
    font-family: 'Roboto', 'Regular' sans-serif;
    
    button{
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 0.4rem;

      transition: filter 0.5s;
      :hover {
        filter: brightness(0.9);
      }
    }
  }

  *{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
`;

export default GlobalStyle;