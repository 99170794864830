import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../../components/painel/headerPainel";
import Menu from "../../../components/painel/menuPainel";
import CardProduto from "../../../components/painel/cardProduto";
// import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import notFound from "../../../assets/images/not_found.svg";
import { Box } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import validaToken from "../../../helpers/validaToken";
import api from "../../../services/api";

function PanelHome() {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleLoding = () => {
    setLoading(false);
  }

  useEffect(() => {
    function getProduto() {
      api.get('/produtos')
        .then((response) => {
          setProdutos(response.data);
          handleLoding();
        }).catch((error) => {
          handleLoding();
        });
    }
    getProduto();
  }, []);

  return (
    <>
      {!validaToken() ? <Redirect to={'/painel'} /> : null}
      <div>
        <Header titulo="" menuShowing />
        <Menu />
      </div>

      <Container maxWidth="sm">
        <br />
        <Box
          display="inline"
          width="498px"
          alignItems="center"
          justifyContent="center"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography color="primary" variant="h6">
                <b>Meus Produtos</b>
              </Typography>
            </div>
            <div>
              <Link to={'/menu/produto'}>
                {" "}
                <AddBoxIcon color="primary" />
              </Link>
            </div>
          </div>
        </Box>
        <Divider />
        {produtos &&
          produtos.map((produto) => {
            return (
              <Box
                display="inline"
                minWidth="500px"
                alignItems="center"
                justifyContent="center"
                key={produto.id}
              >
                <CardProduto produtoid={produto.id} titulo={produto.nome} imagem={produto.foto} ativo={produto.ativo} />
              </Box>
            );
          })}

        {loading && (
          <div style={{ display: 'flex', margin: 'auto', marginTop: '5em ', justifyContent: 'center' }}>
            <center><CircularProgress /></center>
          </div>

        )}


        {produtos.length === 0 && !loading && (
          <center>
            <img alt="" src={notFound} style={{ height: "20em", width: "20em" }} />
            <h4>Nenhum produto adicionado ainda</h4> <br />
            <h5>Que tal adicionar?</h5>
          </center>

        )}
      </Container>
    </>
  );
}

export default PanelHome;
