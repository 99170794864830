import { TextField } from "@material-ui/core";
import React from "react";
import InputMask from "react-input-mask";

const GerCEPMask = ({valor, handlechange, onblur, err, helpertext, classname}) => {
 return( 
  <InputMask      
    mask="99999-999"   
    maskPlaceholder={null}
    maskChar="_"
    onChange={handlechange}
    alwaysShowMask={true}
    value={valor}   
    //onChange={handlechange}
    //onBlur={onblur}
  >
    {() => 
      <TextField   
        error={err}    
        id="cepA"
        name="cep" 
        //helperText={helpertext}          
        className={classname}    
        variant="outlined"  
        size="small"        
      />
    }
  </InputMask>
  )
};
export default GerCEPMask;