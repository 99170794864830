import React, { useState, useEffect} from "react";
import Header from "../../../components/painel/headerPainel";
import { useParams } from 'react-router-dom';
// import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "../../../components/painel/menuPainel";
import Container from "@material-ui/core/Container";
// import AddBoxIcon from "@material-ui/icons/AddBox";
import Typography from "@material-ui/core/Typography";
// import { Box } from "@material-ui/core";
// import Divider from "@material-ui/core/Divider";
// import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { /* useHistory, */ Redirect} from 'react-router-dom';
import validaToken from "../../../helpers/validaToken";
import { toast } from "react-toastify";
import api from "../../../services/api";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function EditGrupo() {
  //const history = useHistory();
  const { id } = useParams();

  const [grupo, setGrupo] = useState("");
  const [messageInformation, setMessageInformation] = useState({
    open: false,
    message: "",
    type: "",
  });

  

  const handleGrupo = (e) => {
    setGrupo(e.target.value);
  };

  const handleOpenClickAviso = (mensagem, tipo) => {
    setMessageInformation({ open: true, message: mensagem, type: tipo });
  };

  const closeAviso = () => {
    setMessageInformation({ ...messageInformation, open: false });
  };

  const handleCloseClickAviso = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeAviso();
  };

  const tryEditGrupo = () => {
    if (!grupo) {
      handleOpenClickAviso("Preencha a descrição do grupo", "warning");
      return;
    }

    api
      .put(`/grupo/${id}`, {
        descricao: grupo,
      })
      .then((response) => {
        handleOpenClickAviso("Grupo alterado com sucesso!", "success");
      })
      .catch((error) => {
        handleOpenClickAviso("Erro interno ao alterar o grupo!", "error");
      });
  };
  
useEffect(() => {
  async function fetchData() {
    await api.get("/estabelecimento")
      .then(async (estabelecimento) => {
        const options = {
          headers: {
            cnpj: estabelecimento.data.cnpj,
          }
        }
        await api.get(`/grupo/${id}`, options)
          .then((response) => {
            setGrupo(response.data.descricao);
          });
      })
      .catch(() => {
        toast.error('Erro ao buscar informações do grupo!')
      })
  }


  if (id) {
    fetchData();
  }
}, [id]);

  return (
    <div>
      {!validaToken() ? <Redirect to="/Painel"/> : null}
      <Header goBack />
      <Menu />
      <Container
        maxWidth="sm"
        style={{
          height: "auto",
          marginTop: "2rem",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          padding: "1rem",
        }}
      >
        {" "}
        <div style={{ width: "100%" }}>
          <Typography color="primary" variant="h6">
            <b>Dados do Grupo</b>
          </Typography>
        </div>
        <div style={{ marginTop: "0.7rem" }}>
          <TextField
            style={{ width: "100%" }}
            id="standard-multiline-flexible"
            label="Descrição do Grupo"
            name="groupName"
            multiline
            onChange={handleGrupo}
            variant="outlined"
            value={grupo}
            required
            rowsMax={4}
          />
        </div>
        <div>
          <Button
            onClick={tryEditGrupo}
            style={{
              width: "100%",
              marginTop: "0.7rem",
              marginBottom: "0.7rem",
            }}
            variant="contained"
            color="primary"
          >
            <p style={{ color: "white" }}>Editar Grupo</p>
          </Button>
        </div>
        <Snackbar
          open={messageInformation.open}
          autoHideDuration={6000}
          onClose={handleCloseClickAviso} 
        >
          <Alert
            onClose={handleCloseClickAviso}
            severity={messageInformation.type}
          >
            {messageInformation.message}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}

export default EditGrupo;
