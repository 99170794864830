import React, { useState} from "react";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import { theme } from "../../../styles/theme";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "550px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  displayContent: {
    display: "inline",
  },
  contentWrapper: {
    marginTop: "12px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const CardCliente = (props) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleExpandClick = () => {
    setIsOpen(!isOpen);
  };

  const formataEndereco = (cliente) => {    
    return "".concat(
      cliente.endereco,
      ", ",
      cliente.numero,
      " - ",
      cliente.bairro
    );
  };

  const mascaraCpf = (valor) => {
    if(valor !== undefined){
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    }else{
      return 'NÃO INFORMADO';
    }
  };

  return (
    <div className={classes.contentWrapper}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>{props.cliente.nome}</div>
              <div>
                <b>CPF: </b> {mascaraCpf(props.cliente.cpf)}
              </div>
            </div>
            <div style={{ height: "0.25rem" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                {formataEndereco(props.cliente)}
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: isOpen,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={isOpen}
                  aria-label="Ver mais">
                <ExpandMoreIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </CardContent>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Informações complementares:</Typography>
            <Typography paragraph>
                <p>Telefone: {props.cliente.telefone}</p><br />
                <p>Email: {props.cliente.email}</p><br/>
                <p>Complemento: {props.cliente.complemento}</p>
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default CardCliente;
