import React, { useState } from 'react';
import {Container, SuportDiv , Cabecalho, Formulario, TextAlign, FormContent, Body, Lembrarme, Footer } from './style';
import { useDispatch } from 'react-redux';
import { postLogin } from '../../../actions/loginActions';
import { Redirect, useHistory } from 'react-router-dom';
import validaToken from "../../../helpers/validaToken";
import { Link } from '@material-ui/core';
import logo from '../../../assets/images/imageicone.png';
import InputCNPJ from '../../../components/Masks/loginMask'

const PainelLogin = (props) => {
  const history = useHistory();

  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const dispatch = useDispatch();

  async function tryLogin(event){
    event.preventDefault();
    try {     

      let newLogin = login.replace(/[^0-9]/g, '');
      await dispatch(postLogin({login: newLogin, senha: senha}));
      // history.push('/menu);
    }
    catch (err) {
      history.push('/painel');
    }
  } 

  return(
    <>
      {validaToken() ? <Redirect to={'/menu'}/> : null}
        <Container>
          <Formulario> 
            <Cabecalho>
              <img src={logo} alt=""/> 
              <TextAlign>
                <h3>Seja bem-vindo!</h3>
                <h6>Preencha os dados de login e senha para acessar o sistema.</h6>  
              </TextAlign> 
            </Cabecalho>  
            <Body>
              <FormContent>
                {/* <input 
                    type="text" 
                    placeholder="Login"
                    onChange={(event) => setLogin(event.target.value)}
                /> */}
              <InputCNPJ 
                placeholder="Login"
                id="login"
                name="login"
                onChange={(event) => setLogin(event.target.value)}
              />
              </FormContent>
              <FormContent>
                <input
                  type="password"
                  placeholder="Senha"
                  onChange={(event) => setSenha(event.target.value)}
                />
              </FormContent> 
              <SuportDiv>
                <Lembrarme>
                  <input type="checkbox" id="scales" name="scales" />
                  <label for="scales">Lembrar-me</label>
                </Lembrarme> 
                <Link href={'/recovery'}> Esqueci a senha</Link> 
              </SuportDiv>
              <button 
                type="submit"
                title='Entrar' 
                onClick={async (event) => await tryLogin(event)} 
              >Entrar
              </button>
            </Body>
            <Footer>
              <h6>
                @2022 Dobes One | Todos os direitos reservados. | <Link href={''}> Política de Privacidade</Link>
              </h6>
            </Footer>       
          </Formulario>
          </Container>
    </>
  );
};

export default PainelLogin;