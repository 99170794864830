import { TextField } from "@material-ui/core";
import React from "react";
import InputMask from "react-input-mask";

const CNPJMask = ({valor, handlechange}) => {
 return( 
  <InputMask
    mask="99.999.999/9999-99"
    maskChar="_"
    maskPlaceholder={null}
    value={valor}
    onChange={handlechange}
    alwaysShowMask={false}
  >
    {() => 
      <TextField
        style={{ display:"flex", width:"50%", alignSelf:"center", textAlign: "center"}} 
        id="cnpjA"
        name="cnpj"        
        variant="outlined"  
        size="small"        
      />
    }
  </InputMask>
  )
};
export default CNPJMask;