import styled from 'styled-components';

export const Container = styled.div`  
    width: 100vw;
    height: 100vh; 
`;
export const CntData = styled.div`  
  display: flex;
  width: 99.8vw;
  height: 90vh;
  justify-content: center;
  vertical-align: middle;
`;

export const ContDiv = styled.div`
  border: 1px solid lightgray;
  border-radius: .5rem;
  background-color: white;
  width: 30rem;
  height: 15rem;
  transform:translate(0%,15%);
  position: relative;
  display:flex;
  flex-direction: column;

  label{
    font-size: 22px;
    align-self: center;
    margin: 1rem;
  }
`;


export const BtnRecover = styled.button`
  border: 1px solid #FF873D;
  background: #FF873D;
  color: white;
  line-height: 2rem;
  font-size:20px;
  font-family: sans-serif;
  cursor: pointer;
  align-self: center ;
  width: 50%;
  margin-top: 1rem;
  border-radius: .4rem;
`;

export const LblTitulo = styled.label`
  font-size: 16px !important;
  width: 50% !important;
  margin: 0 !important;
  margin-top: 1rem !important; 
  margin-bottom: .3rem !important;
`;