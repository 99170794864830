import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import Header from "../../../components/painel/headerPainel";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { Link, Redirect } from "react-router-dom";
import { Box } from "@material-ui/core";
import CardCliente from "../../../components/painel/cardCliente";
import validaToken from "../../../helpers/validaToken";

function Clientes() {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    async function fetchData(){
      await api.get("/clientes").then((response) => {
        setClientes(response.data);
        console.log(response.data);
    });
  }
    fetchData();
  }, []);

  return (
    <>
      {!validaToken() ? <Redirect to="/Painel"/> : null}
      <Header goBack />
      <Container maxWidth="sm">
        <br />
        <Box
          display="inline"
          width="498px"
          alignItems="center"
          justifyContent="center"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography color="primary" variant="h6">
                <b>Clientes</b>
              </Typography>
            </div>
            <div>
              <Link to={"/menu/cliente"}>
                {" "}
                <AddBoxIcon color="primary" />
              </Link>
            </div>
          </div>
        </Box>
        <Divider />
        {clientes.length > 0 &&
          clientes.map((cliente) => {
            return <CardCliente key={cliente.guiid} cliente={cliente} />;
          })}
      </Container>
    </>
  );
}

export default Clientes;
